import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PracticComponent } from './practic/practic.component';
import { RegisterComponent } from './register/register.component';
import { ForgotComponent } from './forgot/forgot.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { DashboardComponent } from './dashboard/dashboard.component'; 
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { ContentDashboardComponent } from './components/content-dashboard/content-dashboard.component';
import { MisSuscripcionesComponent } from './components/mis-suscripciones/mis-suscripciones.component';
import { DireccionesComponent } from './components/direcciones/direcciones.component';
import { AuthGuard } from './guards/auth.guard';
import { ChangePassComponent } from './components/change-pass/change-pass.component';
import { CongratulationComponent } from './components/congratulation/congratulation.component';


const routes: Routes = [
  { path: "", component: PracticComponent },
  { path: 'preview/:id', component: PracticComponent },
  { path: "login", component: LoginComponent },
  { path: 'preview/:id/login', component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: 'preview/:id/register', component: RegisterComponent },
  { path: "forgot", component: ForgotComponent},
  { path: 'preview/:id/forgot', component: ForgotComponent },
  { path: "checkout", component: CheckoutComponent},
  { path: 'preview/:id/checkout', component: CheckoutComponent },
  {path: "congratulation", component:CongratulationComponent},
  {
    path: "dashboard",
    canActivate:  [AuthGuard],
    component: DashboardComponent,
    children: [
      { path: '', redirectTo: 'dashboard-content', pathMatch: 'full' }, 
      { path: 'tarjetas', component: CreditCardComponent },
      { path: 'dashboard-content', component:ContentDashboardComponent},
      { path: 'suscripciones', component:MisSuscripcionesComponent},
      { path: 'direcciones', component:DireccionesComponent},
      { path: 'password', component:ChangePassComponent},
      // Otras rutas hijas del dashboard aquí si es necesario
    ],
  },
  {
    path: "preview/:id/dashboard",
    canActivate:  [AuthGuard],
    component: DashboardComponent,
    children: [
      { path: '', redirectTo: 'dashboard-content', pathMatch: 'full' }, 
      { path: 'tarjetas', component: CreditCardComponent },
      { path: 'dashboard-content', component:ContentDashboardComponent},
      { path: 'suscripciones', component:MisSuscripcionesComponent},
      { path: 'direcciones', component:DireccionesComponent},
      { path: 'password', component:ChangePassComponent},
      // Otras rutas hijas del dashboard aquí si es necesario
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations:[]
})
export class AppRoutingModule { }
