import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatMenuModule } from '@angular/material/menu';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { MatPaginator } from '@angular/material/paginator';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav'; // Importa MatSidenavModule
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { LoginComponent } from './login/login.component';
import { PracticComponent } from './practic/practic.component';
import { PlanpracticComponent } from './partials/plan-practic/plan-practic.component';
import { MenuPreviewComponent } from './partials/menu-preview/menu-preview.component';
import { HeaderComponent } from './header/header.component';
import { RegisterComponent } from './register/register.component';
import { ForgotComponent } from './forgot/forgot.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ContentDashboardComponent } from './components/content-dashboard/content-dashboard.component';
import { AddCardFormComponent } from './components/add-card-form/add-card-form.component';
import { MatDialogActions, MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MisSuscripcionesComponent } from './components/mis-suscripciones/mis-suscripciones.component';
import { DireccionesComponent } from './components/direcciones/direcciones.component';
import { AddAddressComponent } from './components/add-address/add-address.component';
import { ModAddressComponent } from './components/mod-address/mod-address.component';
import { UpdateSuscriptionComponent } from './components/update-suscription/update-suscription.component';
import { ChangePassComponent } from './components/change-pass/change-pass.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import { SuscriptionComponent } from './components/mis-suscripciones/suscription/suscription.component';
import { ValidatePopupComponent } from './components/update-suscription/validate-popup/validate-popup.component';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AddressComponent } from './components/direcciones/address/address.component';
import { UpdateAddressComponent } from './components/update-address/update-address.component';
import { CreditcardInfoComponent } from './components/credit-card/creditcard-info/creditcard-info.component';
import { CreditCardComponent } from './components/credit-card/credit-card.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginPopupComponent } from './login/login-popup/login-popup.component';
import { MistarjetasComponent } from './checkout/mistarjetas/mistarjetas.component';
import { MisdireccionesComponent } from './checkout/misdirecciones/misdirecciones.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CongratulationComponent } from './components/congratulation/congratulation.component';
import { AppDialogContainerComponent } from './checkout/app-dialog-container/app-dialog-container.component';
import {Logging} from "./interceptors/logging.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PracticComponent,
    PlanpracticComponent,
    MenuPreviewComponent,
    HeaderComponent,
    RegisterComponent,
    ForgotComponent,
    CheckoutComponent,
    DashboardComponent,
    SidebarComponent,
    NavbarComponent,
    ContentDashboardComponent,
    HeaderComponent,
    AddCardFormComponent,
    MisSuscripcionesComponent,
    DireccionesComponent,
    AddAddressComponent,
    ModAddressComponent,
    UpdateSuscriptionComponent,
    ChangePassComponent,
    SuscriptionComponent,
    ValidatePopupComponent,
    AddressComponent,
    UpdateAddressComponent,
    CreditCardComponent,
    CreditcardInfoComponent,
    LoginPopupComponent,
    MistarjetasComponent,
    MisdireccionesComponent,
    CongratulationComponent,
    AppDialogContainerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatMenuModule,
    ToastrModule.forRoot({timeOut: 6000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true}), // ToastrModule added
    NgxSpinnerModule,
    MatSidenavModule,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatButtonToggleModule,
        MatListModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        CarouselModule,
        MatTableModule,
        MatPaginatorModule,
        MatTableModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },{ provide: HTTP_INTERCEPTORS, useClass: Logging, multi: true },AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { 
  
}
