<div class="content-dash" [ngClass]="themeService.currentPalette">
    <div class="banner-dash"></div>
<div class="dash-column">
    <div class="column">
        <div class="perfil">
        <img [src]="imagenAleatoria" alt="Foto de perfil" />
        <p>{{ nombreUsuario }}</p>
        </div>
        <div class="profile-data">
            <!-- <div class="direct">
                  <img class="icon" src="../assets/icons/gps.svg" alt="Direcciones">
                  <span>Ciudad de Panamá, San  Francisco</span>   
            </div>  -->
            <hr>
            <span><b>Teléfono:</b></span>
            <p>{{ telefonoUsuario }}</p><br>
            <span><b>Correo:</b></span>
            <p> {{ correoUsuario }}</p>

        </div>

    </div>
<div class="column">
    <div class="menu-dash">
            <button mat-icon-button class="icon" routerLink="/dashboard/dashboard-content" routerLinkActive="active-button" [routerLinkActiveOptions]="{exact: true}" aria-label="">
              <span>Dashboard</span>
            </button>
            <button mat-icon-button class="icon" routerLink="/dashboard/direcciones" routerLinkActive="active-button"   [routerLinkActiveOptions]="{exact: true}"aria-label="">
              <span>Direcciones</span>
            </button>
            <button mat-icon-button class="icon" routerLink="/dashboard/tarjetas" routerLinkActive="active-button" [routerLinkActiveOptions]="{exact: true}" aria-label="">
              <span>Tarjetas</span>
            </button>
            <button mat-icon-button class="icon" routerLink="/dashboard/suscripciones" routerLinkActive="active-button" [routerLinkActiveOptions]="{exact: true}" aria-label="">
              <span>Suscripciones</span>
            </button>
    </div>
    <p>
        Nos complace darte la bienvenida a tu espacio personalizado de {{ nombreEmpresa}}. Este Dashboard es tu centro de control donde podrás gestionar tus actividades, acceder a información importante y aprovechar al máximo nuestros servicios. 
    </p>
</div>
</div>
</div>
