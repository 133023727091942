import { Component, HostListener, Input, OnInit, OnDestroy } from '@angular/core';
import { Plan } from 'src/app/components/plan/model/plan.model';
import { PlanService } from 'src/app/services/plan/plan.service';
import { ThemeService } from '../../theme.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-plan-practic',
  templateUrl: './plan-practic.component.html',
  styleUrls: ['./plan-practic.component.scss']
})
export class PlanpracticComponent implements OnInit, OnDestroy {
  plans: Plan[] = []; // Initialize with empty array
  isLargeScreen = window.innerWidth >= 768;  // Use window object directly
  currentIndex = 0;
  visiblePlans: Plan[] = [];
  @Input() navigation = true;  // Input for navigation toggle

  isPreview: boolean = false; // Use boolean type for clarity
  param: any; // Maintain variable type

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isLargeScreen = event.target.innerWidth >= 768;
    this.updateVisiblePlans();
  }

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    public themeService: ThemeService,
    private planService: PlanService
  ) {
    this.activateRoute.params.subscribe(params => {
      this.isPreview = params["id"] !== undefined;
      if (this.isPreview) {
        this.param = params["id"];
      }
    });
  }

  initCarousel() {
    // Your carousel initialization logic here
  }

  ngOnInit() {
    this.planService.obtainPlans.subscribe((plans: Plan[]) => {
      // Filter plans to only include those that are visible
      this.plans = plans.filter(plan => plan.isVisiblePlan());

      this.updateVisiblePlans();
      this.planService.setIsActive(true); // Assuming setting active after data is loaded
      this.initCarousel(); // Assuming you have a function to initialize the carousel
    });
  }

  ngOnDestroy() {
    // No need for checks, clear interval if it exists
  }

  onPlanHover(plan: Plan) {
    if (plan?.getName()) {
      // Your hover logic here
    } else {
      setTimeout(() => {
        if (plan?.getName()) {
          // Your hover logic here
        }
      }, 100); // Adjust timeout value if needed
    }
  }

  updateVisiblePlans() {
    if (this.plans.length === 0) {
      this.visiblePlans = [];
      return;
    }
    if (this.plans.length === 1) {
      this.visiblePlans = [this.plans[0]];
      return;
    }
    if (this.plans.length === 2) {
      this.visiblePlans = [this.plans[0], this.plans[1]];
      return;
    }

    const startIndex = this.currentIndex % this.plans.length;
    const endIndex = startIndex + (this.isLargeScreen ? 3 : 1);

    this.visiblePlans = endIndex > this.plans.length
      ? [...this.plans.slice(startIndex, this.plans.length), ...this.plans.slice(0, endIndex - this.plans.length)]
      : this.plans.slice(startIndex, endIndex);

    this.visiblePlans.forEach(plan => {
  
    });
  }

  prevSlide() {
    this.currentIndex = (this.currentIndex - 1 + this.plans.length) % this.plans.length;
    this.updateVisiblePlans();
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex + 1) % this.plans.length;
    this.updateVisiblePlans();
  }

  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }

  onSelectPlan(plan: Plan) {
    this.planService.setPlanSelected(plan.getID());
    if (this.isPreview) {
      this.router.navigate(["/preview/" + this.param + "/checkout"], { relativeTo: this.activateRoute, queryParamsHandling: "preserve" });
    } else {
      this.router.navigate(["/checkout"], { relativeTo: this.activateRoute, queryParamsHandling: "preserve" });
    }
  }
}
