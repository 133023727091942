<div class="header" [ngClass]="themeService.currentPalette">
  <div class="content-header">
    <div class="logo" >
      <img (click)="goHome()" src="{{ getLogoUrl() }}" alt="logo">
    </div>
    
    <!-- Menú normal para dispositivos de escritorio -->
    <div  class="menu" *ngIf="!isMobile">
      <a (click)="goHome()">Home</a>
      <a *ngIf="!authService.isLoggedIn()" (click)="goLogin()" >Login</a>
      <a *ngIf="authService.isLoggedIn()" (click)="goDashboard()">Account</a>
    </div>

    <!-- Menú hamburguesa para dispositivos móviles -->
    <!-- Menú hamburguesa para dispositivos móviles -->
<!-- Botón hamburguesa para dispositivos móviles -->
<div class="hamburger-menu" *ngIf="isMobile" (click)="toggleMenu()">
  <button>
    <span></span>
    <span></span>
    <span></span>
  </button>
</div>

<!-- Menú para dispositivos móviles (inicialmente oculto) -->
<div   class="mobile-menu" *ngIf="isMobile && isMenuOpen">
  <ul>
    <li><a (click)="goHome()">Home</a></li>
    <li *ngIf="!authService.isLoggedIn()" ><a (click)="goLogin()" >Login</a></li>
    <li *ngIf="authService.isLoggedIn()" ><a (click)="goDashboard()">Account</a></li>
  </ul>
</div>
  </div>
</div>
