// theme.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  currentPalette: string = 'Billcentrix'; // Paletas: Billcentrix | Violeta | Moderno

  setPalette(palette: string) {
    this.currentPalette = palette;
   // //console.log('Paleta actualizada a:', this.currentPalette);
  }
}
