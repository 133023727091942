<div class="dashlogo" [ngClass]="themeService.currentPalette" (click)="goToHome()" >
   <!--h1>{{ nombreEmpresa.charAt(0).toUpperCase() }}</h1--> 
   <img src="{{ getLogoUrl() }}" alt="logo">
</div>

<div class="options" [ngClass]="themeService.currentPalette">
  <div class="items-menu">
      <button mat-icon-button class="icon" routerLink="/dashboard/dashboard-content" routerLinkActive="active-button" [routerLinkActiveOptions]="{exact: true}" aria-label="">
        <img class="icon" src="../assets/icons/dashboard.svg" alt="Dashboard">
        <span>Dashboard</span>
      </button>
    </div>
    <div class="items-menu">
      <button mat-icon-button class="icon" routerLink="/dashboard/suscripciones" routerLinkActive="active-button"   [routerLinkActiveOptions]="{exact: true}"aria-label="">
        <img style="padding: 5px;" class="icon" src="../assets/icons/subscription.png" alt="Suscripciones">
        <span>Suscripciones</span>
      </button>
    </div>
    <div class="items-menu">
      <button mat-icon-button class="icon" routerLink="/dashboard/direcciones" routerLinkActive="active-button"   [routerLinkActiveOptions]="{exact: true}"aria-label="">
        <img class="icon" src="../assets/icons/gps.svg" alt="Direcciones">
        <span>Direcciones</span>
      </button>
    </div>
    <div class="items-menu">
      <button mat-icon-button class="icon" routerLink="/dashboard/tarjetas" routerLinkActive="active-button" [routerLinkActiveOptions]="{exact: true}" aria-label="">
        <img style="padding: 5px;" class="icon" src="../assets/icons/credit-card.png" alt="Tarjetas">
        <span>Tarjetas</span>
      </button>
    </div>
    <div class="items-menu">
      <button mat-icon-button class="icon" routerLink="/dashboard/password" routerLinkActive="active-button" [routerLinkActiveOptions]="{exact: true}" aria-label="">
        <img style="padding: 5px;" class="icon" src="../assets/icons/password.png" alt="password">
        <span>Contraseña</span>
      </button>
    </div>
    <!--img class="banner_promo"  src="../assets/images/promo_billcentrix.png" alt=""-->
    
</div>