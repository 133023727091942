<div class="plan-practic" [ngClass]="themeService.currentPalette">
    <div class="plan-practic-content" *ngIf="visiblePlans">
      <carousel class="carru" [ngClass]="{'single-carousel': visiblePlans.length === 1}" [itemsPerSlide]="isLargeScreen ? 2 : 1">
        <slide *ngFor="let plan of visiblePlans" class="slider" [ngClass]="{'single-plan': visiblePlans.length === 1}">
              <div class="carousel-container">
                <div class="preview">
                  <p>{{ plan.getName() }}</p>
                  <h1 class="big-numbers">${{ plan.getPrice() }}</h1>
                  <div class="description">
                    <p #descripcionPlan>{{ plan.getDescription() }}</p>
                  </div>
                    <button (click)="onSelectPlan(plan)" class="custom-button">
                      Seleccionar
                      <mat-icon class="circle-icon">radio_button_unchecked</mat-icon>
                    </button>
                    
                </div>
              </div>
            </slide>
            <div class="carousel-controls">
              <button class="carousel-nav-left" (click)="prevSlide()"> <mat-icon>arrow_back</mat-icon></button>
              <button class="carousel-nav-right" (click)="nextSlide()"><mat-icon>arrow_forward</mat-icon></button>
            </div> 
          </carousel>
    </div>
</div>